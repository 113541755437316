import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import BackgroundImage from "gatsby-background-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import ModalGallery from "../components/Modal/ModalGallery";

const Page = ({ data }) => {
   const [slideIndex, setSlideIndex] = useState(0);

   const processBgImages = [
      data.processBgDesktop.childImageSharp.fixed,
      {
         ...data.processBgDesktop.childImageSharp.fixed,
         media: `(max-width: 767px)`,
      },
   ];

   const url = typeof window !== "undefined" ? window.location.pathname : "";

   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Vacuum Forming & Thermoforming Company | Painted Rhino"
            description="Painted Rhino is a top vacuum forming and thermoforming company based in California and serving nationwide. See what we can do and get a free quote!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <section className="pt-6 md:pt-14 pb-20 md:pb-32">
            <div className="container">
               <header className="mb-12">
                  <h1>Vacuum Forming & Thermoforming</h1>
               </header>

               <div className="mb-12 md:mb-28">
                  <Img fadeIn={false} loading="eager" fluid={data.heroDesktop.childImageSharp.fluid} alt="Fiberglass & Composites" />
               </div>

               <div className="grid grid-cols-1 md:grid-cols-12 md:gap-x-20">
                  <div className="md:col-start-1 md:col-span-6">
                     <h2>Your Vacuum Forming & Thermoforming Company</h2>
                  </div>
                  <div className="md:col-end-13 md:col-span-6">
                     <p className="mb-0">
                        At Painted Rhino, our capacities include these plastic molding techniques: vacuum forming and thermoforming. Thermoforming
                        involves heating plastic sheets until it is pliable and once it reaches its softening point, it is formed over a mold. At our
                        vacuum forming company, our process is similar to <a href="https://www.paintedrhino.com/vacuum-forming-thermoforming-company/" className="text-[#006ee8]">thermoforming</a> but with additional vacuum pressure to assist with molding.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <BackgroundImage fixed={processBgImages} className="w-full pt-22 md:pt-32 pb-18 md:pb-36">
            <div className="container relative">
               <header className="mb-18 md:mb-22 max-w-sm">
                  <h3>Our Vacuum Forming & Thermoforming Processes</h3>
               </header>

               <ScrollWrapper>
                  <ScrollNavigation>
                     <li>
                        <AnchorLink to={url + "#section-1"} title="Heavy Gauge Thermoforming Process" stripHash />
                     </li>
                     <li>
                        <AnchorLink to={url + "#section-2"} title="Vacuum Forming" stripHash />
                     </li>
                  </ScrollNavigation>

                  <ScrollContent>
                     <div id="section-1" className="md:bg-white md:pt-14 md:pb-12 md:px-14 mb-12 lg:mb-20">
                        <h4>Heavy Gauge Thermoforming Process</h4>
                        <p className="mb-0">
                           This process is also known as thick gauge thermoforming. Our plastic forming machines cut thermoplastic material and move
                           them through heating stations until they are ready for molding. The material goes through secondary processes that could
                           involve drilling, assembly or other operations as needed. Thermoplastic sheets are well-suited for enclosures (for medical
                           devices and computers, etc.), heavy truck components, aircraft parts, construction equipment, and more. Common materials
                           used in this process include acrylic, ABS, FR, HIPS, HDPE, and polycarbonates. At our thermoforming company, this process
                           can further be categorized into the following techniques: pressure forming, drape forming, and vacuum forming.
                        </p>
                     </div>

                     <div id="section-2" className="md:bg-white md:pt-14 md:pb-12 md:px-14">
                        <h4>Vacuum Forming</h4>
                        <p className="mb-0">
                           What goes into vacuum forming is similar to the heavy gauge thermoforming process, but a vacuum is used to force the
                           material against the mold. Vacuum forming is flexible, cost-effective and suitable for parts that need a quick turn-around.
                           Many of the things we rely on daily are produced through vacuum forming such as automotive and transportation parts,
                           medical components, food packaging, and many more. Whatever your specific needs are, our vacuum forming company can help.
                        </p>
                     </div>
                  </ScrollContent>
               </ScrollWrapper>
            </div>
         </BackgroundImage>

         <section className="py-20 md:py-32">
            <div className="container">
               <div className="grid md:grid-cols-2 gap-2 md:gap-4">
                  <div className="grid grid-rows-2 grid-cols-10 gap-2 md:gap-4">
                     <div className="col-start-1 col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(0)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail1.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-end-11 col-span-4 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(1)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail2.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-start-1 col-span-4 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(2)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail3.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-end-11 col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(3)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail4.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
                  <div className="grid grid-rows-2 grid-cols-10 grid-flow-col gap-2 md:gap-4">
                     <div className="col-span-4 row-span-2">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(4)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail5.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(5)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail6.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="row-span-1 col-span-6">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(6)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="w-full h-full"
                              fluid={data.galleryThumbnail7.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <TestimonialBackgroundImage />

         <WhyUs
            className="pt-22 md:pt-32 pb-20 md:pb-28"
            contentAlignment="md:text-center md:mx-auto"
            headerHeading="The Painted Rhino Difference."
         />

         <CTABackgroundImage />

         <ModalGallery slideIndex={slideIndex}>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox1.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox2.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox3.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox4.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox5.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox6.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox7.childImageSharp.fluid} alt="" />
            </div>
         </ModalGallery>
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Vacuum Forming _ Thermoforming_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Vacuum Forming _ Thermoforming_Twitter.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "services/vacuum-forming-thermoforming.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2400) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      processBgDesktop: file(relativePath: { eq: "services/process-bg-desktop.jpg" }) {
         childImageSharp {
            fixed(width: 2880, height: 1432) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      galleryThumbnail1: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Top-left.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 355) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox1: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/Rhino Front.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail2: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Top-middle.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 221) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox2: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/ac 5.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail3: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Bottom-left.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox3: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/046.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail4: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Bottom-middle.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox4: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/Race Car Dash.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail5: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Middle.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox5: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/abs.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail6: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Top-right.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox6: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/abs 2.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail7: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Thumbnails/Bottom-right.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox7: file(relativePath: { eq: "services/Vacuum-Forming-Thermoforming/Gallery/Lightbox/Warrior Front Cap.jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
   }
`;

export default Page;
